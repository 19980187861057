import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: black;
  box-shadow: 0 4px 2px -2px black;
  @media (max-width: 900px) {
    position: fixed;
    z-index: 80;
    width: 100%;
    height: 110px;
    top: 0;
    background-color: black;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 95px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  margin-right: 78px;
`;

export const Logo = styled.img`
  width: 170px;
`;

export const StyledLink = styled(Link)`
  position: relative;
  font-size: 18px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  margin: 20px 10px 0px;
  padding-left: 5px;
  padding-right: 5px;
  ${({ isActive }) =>
    isActive &&
    css`
      background-image: url("/basc/orange-background.png");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: fit;
    `};
`;

export const NavBarLink = styled.a`
  position: relative;
  font-size: 18px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  margin: 20px 15px 0px;
  white-space: nowrap;
`;

export const SocialIcon = styled.img`
  width: 40px;
  height: auto;
  @media (max-width: 450px) {
    width: 30px;
    height: auto;
  }
`;

export const MobileNavBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin: 0 auto;
`;

export const MobileNavMenu = styled.div`
  background: url("/basc/orange-background.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  z-index: 2000;
  position: fixed;
  top: 0;
  width: 100%;
  color: #fff;
  text-align: center;

  opacity: 0;
  visibility: hidden;
  height: 0%;
  transition: opacity 0.75s, visibility 0.75s, height 0.75s;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen
      ? css`
          opacity: 1;
          visibility: visible;
          height: 460px;
          max-height: 100vh;
          overflow-y: scroll;
        `
      : ""}
`;

export const MobileNavLogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
`;
export const MobileLogo = styled.a`
  margin-left: 20px;
  margin-top: 10px;
`;

export const MobileLinkStyle = styled.div`
  font-size: 20px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 0;
`;

export const Hr = styled.hr`
  background-color: rgb(255, 255, 255);
  opacity: 0.4;
  height: 1px;
  width: 100%;
`;

export const MobileLinksWrapper = styled.div`
  padding: 10px 20px;
`;

export const SocialLinksMobile = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const HamButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;

  position: relative;
  font-size: 16px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: 6px 15px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const BuyButton = styled.a`
  color: white;
  background: -webkit-linear-gradient(left, #ff2121, #ff9921);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  border: none;
  border-radius: 5px;
  width: 100px;
  display: block;
  min-width: 100px;
  padding: 8px 10px;
  text-align: center;
  font-family: "Oswald";
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: bold;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "Oswald";
  font-weight: bold;
  min-width: 140px;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  background-color: rgb(254, 153, 34);
  flex-direction: column;
  padding-bottom: 30px;
  left: -100%;
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  margin: 20px 15px 0px;
  white-space: nowrap;

  &:hover {
    ${DropdownContent} {
      display: flex;
    }
  }
`;
