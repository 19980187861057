const theme = {
  // Theme is deeply merged with default theme
  colorScheme: "dark",
  fontFamily: "Poppins",
  headings: { fontFamily: "Oswald" },
  primaryColor: "orange",
  shadows: {
    // other shadows (xs, sm, lg) will be merged from default theme
    md: "1px 1px 3px rgba(0,0,0,.25)",
    xl: "5px 5px 3px rgba(0,0,0,.25)",
  },
};
export default theme;
