/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Wheel as CustomWheel } from "react-custom-roulette";
import {
  Container,
  Group,
  Image,
  Skeleton,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { StoreContext } from "../../providers/StoreProvider";
import ConfirmationModal from "../../pages/home/components/ConfirmationModal";

function Wheel({
  spinning,
  winningPrize,
  handleSpinEnd,
  winningPrizeNumber,
  data,
}) {
  const { prizes, sendPrize } = React.useContext(StoreContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [prizeSent, setPrizeSent] = useState(undefined);
  const theme = useMantineTheme();
  const wheelSliceColors = [
    "#fbe1a1",
    "#f9e612",
    "#fbe1a1",
    "#f9e612",
    "#fbe1a1",
    "#f9e612",
    "#fbe1a1",
    "#f9e612",
    "#fbe1a1",
    "#f9e612",
  ];

  const onPrizeWon = async () => {
    if (!winningPrize)
      showNotification({
        id: "error",
        title: "Error!",
        message: "No winning spin found",
        color: "red",
      });
    else {
      const result = await sendPrize(winningPrize.id);
      setPrizeSent(result);
      handleSpinEnd();
      if (result) setConfirmationModalOpen(true);
    }
  };

  return (
    <>
      <Container size={1200} className="p-0">
        <Group className="relative" direction="column" align="center">
          <Image
            className="absolute max-w-[100vw] md:w-[500px]"
            src="/basc/wheel-title.png"
          />
          <Group className="mt-20" position="center">
            {!prizes?.length && (
              <Skeleton
                height={500}
                className="md:block hidden"
                circle
                mb="xl"
              />
            )}
            {!!prizes?.length && (
              <div className="relative">
                <div className="h-[60px] w-[60px] md:h-[110px] md:w-[110px] border-4 bg-basc-yellow border-black border-solid absolute z-20 top-50 left-50 rounded-full flex justify-center items-center">
                  <img
                    alt="logo"
                    src="/basc/logo2.png"
                    className="my-auto w-[50px] md:w-[80px]"
                  />
                </div>
                <div className="outer-bg bg-center bg-no-repeat md:w-[590px] md:h-[590px] flex justify-center items-center">
                  <div className="bg-wheel bg-center bg-no-repeat md:w-[510px] md:h-[510px] flex justify-center items-center">
                    <div className="wheel">
                      <CustomWheel
                        mustStartSpinning={spinning}
                        prizeNumber={winningPrizeNumber}
                        data={data}
                        backgroundColors={wheelSliceColors}
                        textColors={["#2c1111"]}
                        textDistance={60}
                        fontSize={18}
                        radiusLineColor="white"
                        outerBorderColor="white"
                        innerRadius={20}
                        radiusLineWidth={3}
                        onStopSpinning={onPrizeWon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Group>
        </Group>
      </Container>
      <ConfirmationModal
        opened={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        winningPrize={prizeSent}
      />
    </>
  );
}

export default Wheel;
