/* eslint-disable no-unused-vars */
import {
  Accordion,
  Anchor,
  Container,
  Group,
  Space,
  Text,
  ThemeIcon,
} from "@mantine/core";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { ExternalLink } from "tabler-icons-react";
import api from "../../../api/api";
import { StoreContext } from "../../../providers/StoreProvider";
import { shortAddress } from "../../../utils/utils";
import LineItems from "../../LineItems";

const columns = [
  {
    label: "Credit Id",
    id: "_id",
    cols: 3,
    short: true,
    withCopyTooltip: true,
  },
  {
    label: "Burn txn",
    id: "burnTransactionId",
    cols: 3,
    short: true,
  },
  {
    label: "Validated",
    id: "burnComplete",
    cols: 2,
    short: false,
    checkbox: true,
  },
  {
    label: "Admin Assigned",
    id: "isAdminAssigned",
    cols: 3,
    short: false,
    checkbox: true,
  },
  {
    label: "Used",
    id: "prize",
    cols: 3,
    short: false,
    checkbox: true,
  },
];
export default function Credits() {
  const { user } = React.useContext(StoreContext);
  const [userSpins, setUserSpins] = useState([]);

  const getUserSpins = async () => {
    if (!user) return;
    const result = await api.get({
      endpoint: `/spins/users/credits/${user._id}`,
    });
    setUserSpins(result?.data || []);
  };

  useEffect(() => {
    getUserSpins(0);
  }, []);

  return (
    <Container>
      <Accordion iconSize={14} multiple>
        {userSpins?.map((purchase) => {
          return (
            <Accordion.Item
              key={purchase._id}
              label={
                <Group position="apart">
                  <Text>
                    {dayjs(purchase.purchases[0].createdAt).format(
                      "DD/MM/YYYY hh:mm a"
                    )}
                  </Text>
                  <Group>
                    <Text color="grape">{purchase.credits} Credit(s) </Text>
                    <Text>{purchase.credits - purchase.avaliable} Used </Text>
                    <Text color={purchase.avaliable ? "green" : "red"}>
                      {purchase.avaliable} Avaliable{" "}
                    </Text>
                  </Group>

                  <Anchor
                    color="blue"
                    href={`https://solscan.io/tx/${purchase._id}`}
                  >
                    <Group spacing="xs" align="center">
                      <ThemeIcon size="sm" color="blue">
                        <ExternalLink />
                      </ThemeIcon>
                      <Text color="blue">{shortAddress(purchase._id)}</Text>
                    </Group>
                  </Anchor>
                </Group>
              }
            >
              <LineItems columns={columns} items={purchase.purchases} />
            </Accordion.Item>
          );
        })}
      </Accordion>

      <Space h="lg" />
    </Container>
  );
}
