import React, { useState } from "react";
import {
  Button,
  Modal,
  Stack,
  Text,
  NumberInput,
  Title,
  Group,
} from "@mantine/core";
import { StoreContext } from "../providers/StoreProvider";

export default function CreditModal({ opened, setOpened }) {
  const { signMultipleTransactions, credits, getSpins } =
    React.useContext(StoreContext);
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleBuyMultipleTickets = async () => {
    if (!value) return;
    setLoading(true);
    const res = await signMultipleTransactions(parseInt(value, 10));
    setLoading(false);
    setComplete(res);
    setValue(1);
  };

  const handleClose = async () => {
    setOpened(false);
    await getSpins();
    setComplete(false);
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Title order={2}>Buy Spin credits</Title>}
        withCloseButton
      >
        {complete ? (
          <Stack>
            <Text>
              Success! Your credits have been purchased, please wait a few
              minutes for the transaction to be confirmed on the blockchain then
              refresh the page.
            </Text>
            <Button onClick={handleClose}>Close</Button>
          </Stack>
        ) : (
          <Stack>
            <Text className="text-gray-500 font-semibold">
              You currently have {credits.length} credit(s).
            </Text>
            <Text>
              Spin Credits are another form of “currency” for the spinner.
              Instead of spinning and deducting the token from your wallet each
              time, you can instead purchase Spin Credit(s) that can be used in
              place. This will allow you to spin without waiting for the burn
              transaction to finish, thus speeding up your spins!
            </Text>
            <Title order={3}> How do you use Spin Credits?</Title>
            <Text>
              To use your Spin Credit(s), simply choose to “Spin Wheel” and then
              select “Use Credit” as your option. As long as you have more than
              0 credits you should have no trouble using them.
            </Text>
            <div>
              <Title order={4}>Purchase Credits</Title>
              <Group grow>
                <NumberInput
                  value={value}
                  defaultValue={value}
                  onChange={(val) => setValue(val)}
                  label="Number of spin credits"
                  min={1}
                />
                <Button variant="outline" className="mt-auto">
                  Total:{" "}
                  {value * parseInt(process.env.REACT_APP_PRICE || 0, 10)} $
                  {process.env.REACT_APP_TOKEN_SYMBOL}
                </Button>
              </Group>
            </div>
            <Button
              loading={loading}
              disabled={loading || !value}
              onClick={handleBuyMultipleTickets}
            >
              Buy credit(s)
            </Button>
          </Stack>
        )}
      </Modal>
    </>
  );
}
