import React, { useState, useEffect } from "react";
import { Button, Container, Group } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import UserDrawer from "./UserDrawer/UserDrawer";
import Wallet from "./Wallet";
import { StoreContext } from "../providers/StoreProvider";
import ConnectWalletPopper from "../pages/home/components/ConnectWalletPopper";

function Subheader() {
  const { user } = React.useContext(StoreContext);
  const wallet = useWallet();
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [warningReferenceElement, setWarningReferenceElement] = useState(null);
  const [connectWalletWarningVisible, setConnectWalletWarningVisible] =
    useState(false);
  const noActiveUser = !wallet.connected || !user?.id;

  useEffect(() => {
    if (!user) {
      setUserDrawerOpen(false);
      setConnectWalletWarningVisible(false);
    }
  }, [user]);

  const onMySpinsClick = () => {
    return noActiveUser
      ? setConnectWalletWarningVisible(!connectWalletWarningVisible)
      : setUserDrawerOpen(true);
  };

  return (
    <>
      <Container size={1500} className="px-5">
        <Group className="py-2 mb-5 flex-nowrap sm:flex-wrap" position="apart">
          <Button
            variant="outline"
            onClick={() => onMySpinsClick()}
            ref={setWarningReferenceElement}
          >
            My spins
          </Button>
          <ConnectWalletPopper
            warningReferenceElement={warningReferenceElement}
            connectWalletWarningVisible={connectWalletWarningVisible}
          />
          <Wallet
            closeConnectWalletWarning={() =>
              setConnectWalletWarningVisible(false)
            }
          />
        </Group>
      </Container>
      <UserDrawer
        opened={userDrawerOpen}
        onClose={() => setUserDrawerOpen(false)}
      />
    </>
  );
}

export default Subheader;
