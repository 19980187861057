/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSinglePrismicDocument } from "@prismicio/react";

import { X } from "tabler-icons-react";
import {
  MobileLogo,
  LinksWrapper,
  ContentWrapper,
  MobileNavBar,
  SocialIcons,
  SocialIcon,
  NavBarLink,
  MobileNavMenu,
  MobileLinksWrapper,
  MobileLinkStyle,
  Hr,
  SocialLinksMobile,
  MobileNavLogoWrapper,
  CloseButton,
  HamButton,
  LogoWrapper,
  Dropdown,
  DropdownContent,
} from "./Header.style";
import useWindowSize from "../../utils/hooks/useWindowSize";

function Header() {
  const [prismicNav] = useSinglePrismicDocument("header");
  const size = useWindowSize();
  const isTabPort = size.width < 900;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) window.document.body.classList.add("no-scroll");
    else window.document.body.classList.remove("no-scroll");
  }, [isOpen]);

  const toggleMobileNav = () => {
    setIsOpen(!isOpen);
    window.scrollTo(0, 0);
  };

  if (!size.width || !prismicNav) return <></>;

  const getDropdown = (label, links) => {
    return (
      <Dropdown>
        <span>{label}</span>
        <DropdownContent>
          {links.map((link) => {
            return (
              <NavBarLink key={link.label} href={link.link.url} target="_blank">
                {link.label}
              </NavBarLink>
            );
          })}
        </DropdownContent>
      </Dropdown>
    );
  };

  const getDropdownMobile = (label, links) => {
    return links.map((link) => {
      return (
        <NavBarLink key={link.label} href={link.link.url} target="_blank">
          {link.label}
        </NavBarLink>
      );
    });
  };

  return (
    <>
      {isTabPort && (
        <MobileNavBar>
          <MobileLogo href="/">
            <img src="/basc/logo.png" width="100px" alt="logo" />
          </MobileLogo>
          <HamButton onClick={() => setIsOpen(!isOpen)}>
            <img src="/basc/ham-menu.png" width="20px" />
          </HamButton>
        </MobileNavBar>
      )}
      {!isTabPort && (
        <ContentWrapper>
          <SocialIcons>
            <a
              href="https://discord.com/invite/2uNZYSKvsw"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="/basc/discord2.png" />
            </a>
            <a
              href="https://twitter.com/BabyApe_SC?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="/basc/twitter2.png" />
            </a>
          </SocialIcons>
          <LinksWrapper>
            {prismicNav?.data?.body?.map((slice, i) => {
              if (slice.slice_type === "link")
                return (
                  <NavBarLink
                    key={slice.primary.label}
                    href={slice.primary.link.url}
                  >
                    {slice.primary.label}
                  </NavBarLink>
                );
              if (slice.slice_type === "logo_link")
                return (
                  <LogoWrapper key={i}>
                    <a href={slice.primary.link.url} width="140px">
                      <img
                        src={slice.primary.logo1.url}
                        width="100px"
                        alt="logo"
                      />
                    </a>
                  </LogoWrapper>
                );
              if (slice.slice_type === "dropdown")
                return getDropdown(slice.primary.label, slice.items);
              return <></>;
            })}
          </LinksWrapper>

          <SocialIcons>
            <a
              href="https://magiceden.io/marketplace/baby_ape_social_club"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="/basc/magic-eden.png" />
            </a>
            <a
              href="https://www.instagram.com/babyapesocialclub/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="/basc/instagram2.png" />
            </a>
          </SocialIcons>
        </ContentWrapper>
      )}
      <MobileNavMenu isOpen={isOpen}>
        <MobileNavLogoWrapper>
          <img src="/basc/logo.png" width="70px" />
          <CloseButton onClick={toggleMobileNav}>
            <X color="white" />
          </CloseButton>
        </MobileNavLogoWrapper>
        <MobileLinksWrapper>
          {prismicNav?.data?.body?.map((slice) => {
            if (!slice.primary.link?.url) return <></>;
            if (slice.slice_type === "link") {
              return (
                <div>
                  <a
                    style={{ textDecoration: "none" }}
                    href={slice.primary.link.url}
                    onClick={toggleMobileNav}
                    rel="noreferrer"
                  >
                    <MobileLinkStyle>{slice.primary.label}</MobileLinkStyle>
                  </a>
                  <Hr />
                </div>
              );
            }
            if (slice.slice_type === "dropdown")
              return getDropdownMobile(slice.primary.label, slice.items);
            return <></>;
          })}
        </MobileLinksWrapper>
        <SocialLinksMobile>
          <a
            href="https://discord.com/invite/2uNZYSKvsw"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="/basc/discord2.png" />
          </a>
          <a
            href="https://twitter.com/BabyApe_SC?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="/basc/twitter2.png" />
          </a>
          <a
            href="https://magiceden.io/marketplace/baby_ape_social_club"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="/basc/magic-eden.png" />
          </a>
          <a
            href="https://www.instagram.com/babyapesocialclub/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="/basc/instagram2.png" />
          </a>
        </SocialLinksMobile>
      </MobileNavMenu>
    </>
  );
}

export default Header;
